import { Dashboard } from "@mui/icons-material";
import styled from "styled-components";
import { Button, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { SearchContainer } from "../SearchContainer";

export const Header = () => {
	const navigate = useNavigate();
	return (
		<Grid container justifyContent="flex-start" alignItems="center" item marginTop={2}>
			<AppLogo
				onClick={() => navigate("/")}
				item
				xl={4}
				xs={12}
				container
				justifyContent="center"
				alignItems="center"
				marginTop={2}>
				<Dashboard />
				<Typography variant="h5" fontWeight="bold">
					Patent analytics beta
				</Typography>
			</AppLogo>
			<Grid container justifyContent="center" item xl={4} xs={12} marginTop={2} paddingX="2%">
				<SearchContainer />
			</Grid>

			<Grid container justifyContent="center" alignItems="center" item xl={4} xs={12} marginTop={2}>
				<Grid item>
					<Link to="/about">About</Link>
				</Grid>
				<Grid item marginLeft={5}>
					<Link to="/dashboard">US Patent Dashboard</Link>
				</Grid>
				<Grid item marginLeft={5}>
					<Button variant="contained">Sign up</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

const AppLogo = styled(Grid)`
	cursor: pointer;
`;
