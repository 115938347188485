import { ActivePatentsGraphic, PatentsByCategoryGraphic, PatentsTable } from "./components";

export const CompanyDetails = () => {
	return (
		<>
			<ActivePatentsGraphic />
			<PatentsByCategoryGraphic />
			<PatentsTable />
		</>
	);
};
