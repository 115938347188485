import { createContext, ReactNode, useContext, useMemo, useState, useCallback, useEffect } from "react";
import { useFindPatents } from "../api/endpoints/Patents/useFindPatents";
import { YEARS_ARRAY } from "../config";
import { buildQueryToGetAllPatents } from "../utils";

export const AppContext = createContext<any>({});

export type AppContextProviderProps = {
	children: ReactNode;
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
	const [companyData, setCompanyData] = useState({});
	const [companyYearlyFilledPatents, setCompanyYearlyFilledPatents] = useState({});
	const [companyNumberOfPatents, setCompanyNumberOfPatents] = useState({});
	const [companyGrantedPatents, setCompanyGrantedPatents] = useState({});
	const [allPatentsByYear, setAllPatentsByYear] = useState({});
	const [assigneesData, setAssigneesData] = useState({});
	const { call: callFindPatents } = useFindPatents();

	const handleGetAllPatentsByYear = useCallback(async () => {
		let data = {};
		for (let i = 0; i < YEARS_ARRAY.length; i++) {
			const year = YEARS_ARRAY[i];
			const query = buildQueryToGetAllPatents(year);
			const response = await callFindPatents({ query });
			data = {
				...data,
				[year]: response?.total_patent_count,
			};
		}
		setAllPatentsByYear(data);
	}, [callFindPatents]);

	useEffect(() => {
		if (!Object.keys(allPatentsByYear).length) {
			handleGetAllPatentsByYear();
		}
	}, [callFindPatents, handleGetAllPatentsByYear, allPatentsByYear]);

	const value = useMemo(
		() => ({
			companyData,
			setCompanyData,
			companyYearlyFilledPatents,
			setCompanyYearlyFilledPatents,
			companyNumberOfPatents,
			setCompanyNumberOfPatents,
			companyGrantedPatents,
			setCompanyGrantedPatents,
			assigneesData,
			setAssigneesData,
			allPatentsByYear,
		}),
		[
			companyData,
			companyYearlyFilledPatents,
			companyNumberOfPatents,
			companyGrantedPatents,
			assigneesData,
			allPatentsByYear,
		]
	);

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext<any>(AppContext);
