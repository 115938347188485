export const API_URL = process.env.REACT_APP_API_URL as string;

export const INIT_PATENT_YEAR = 1976;

export const CURRENT_YEAR = Number(new Date().getFullYear());

export const YEARS_ARRAY = (() => {
	const yearsArray: number[] = [];
	for (let i = INIT_PATENT_YEAR; i <= CURRENT_YEAR; i++) {
		yearsArray.push(i);
	}
	return yearsArray;
})();
