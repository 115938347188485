import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import {
  buildQueryToGetFilledPatents,
  buildQueryToGetFilledPatentsByYear,
  buildQueryToGetGrantedPatents,
  buildQueryToSearchPatents,
  filterPatents,
  capitalizeFirstLetter,
} from "../../utils";
import {
  BasicCompanyInformation,
  ListResults,
  CompanyDetails,
  Header,
} from "../../components";
import { useFindAssignees } from "../../api/endpoints/Assignees/useFindAssignees";
import { useFindPatents } from "../../api/endpoints/Patents/useFindPatents";
import { tabsEnum, tabsArray } from "./utils";

interface TabProps {
  active: boolean;
}

export const AnalyticsContainer = () => {
  const {
    companyData,
    setCompanyYearlyFilledPatents,
    setCompanyNumberOfPatents,
    setCompanyGrantedPatents,
    assigneesData,
    setCompanyData,
    setAssigneesData,
  } = useAppContext();
  const { call: callFindAssignees } = useFindAssignees();
  const { call: callFindPatents } = useFindPatents();
  const [activeTab, setActiveTab] = useState(tabsEnum.OVERVIEW);
  const navigate = useNavigate();

  const handleClick = (company: any) => {
    setCompanyData(company);
    setAssigneesData({});
  };

  useEffect(() => {
    if (!Object.keys(companyData).length) {
      navigate("/");
    }
  }, [companyData, navigate]);

  const handleTabClick = (tab: string) => () => {
    setActiveTab(tab);
  };

  const renderComponent = () => {
    switch (activeTab) {
      case tabsEnum.OVERVIEW:
        return <BasicCompanyInformation />;
      case tabsEnum.DETAILS:
        return <CompanyDetails />;
    }
  };

  useEffect(() => {
    (async () => {
      if (!companyData) return;
      let query = buildQueryToGetFilledPatentsByYear(companyData.assignee_id);
      let data = await callFindAssignees({ query });
      setCompanyYearlyFilledPatents?.(data?.assignees?.[0]);

      query = buildQueryToSearchPatents(companyData.assignee_id);
      data = await callFindPatents({ query });
      const filteredData = filterPatents(data);
      setCompanyNumberOfPatents(filteredData);

      query = buildQueryToGetGrantedPatents(companyData.assignee_id);
      data = await callFindPatents({ query });
      setCompanyGrantedPatents(data);

      query = buildQueryToGetFilledPatents(companyData.assignee_id);
      data = await callFindPatents({ query });
    })();
  }, [
    companyData,
    callFindAssignees,
    callFindPatents,
    setCompanyNumberOfPatents,
    setCompanyYearlyFilledPatents,
    setCompanyGrantedPatents,
  ]);

  return (
    <Grid
      container
      minHeight="100vh"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
    >
      <Header />
      <Grid item xl={5} md={7} xs={12} marginRight={2}>
        {!!assigneesData?.assignees?.length && (
          <ListResults
            searchResults={assigneesData.assignees}
            handleClick={handleClick}
          />
        )}
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        xl={9}
        md={10}
        xs={12}
        marginTop={8}
        paddingX="2%"
      >
        <Card>
          <Typography variant="h5" fontWeight="bold">
            {companyData.assignee_organization}
          </Typography>
          <TabsContainer>
            {tabsArray.map((tab: string) => (
              <Tab
                key={tab}
                active={tab === activeTab}
                onClick={handleTabClick(tab)}
              >
                {capitalizeFirstLetter(tab)}
              </Tab>
            ))}
          </TabsContainer>
          {renderComponent()}
        </Card>
      </Grid>
    </Grid>
  );
};

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 2%;
  border-radius: 10px;
  background-color: #fff;
  width: 90%;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
`;

const Tab = styled.span<TabProps>`
  padding: 3px 12px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  background-color: ${({ active }) => (active ? "#ebebeb" : "none")};
  margin-left: 5px;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
`;
