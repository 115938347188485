export const buildQueryToSearchCompany = (company: string): any => ({
	q: JSON.stringify({
		_and: [
			{
				_or: [
					{ _and: [{ _contains: { assignee_first_name: company } }] },
					{ _and: [{ _contains: { assignee_last_name: company } }] },
					{ _and: [{ _contains: { assignee_organization: company } }] },
				],
			},
		],
	}),
	f: JSON.stringify(["assignee_id", "assignee_organization", "assignee_total_num_patents", "patent_id"]),
	o: JSON.stringify({
		per_page: 10,
		matched_subentities_only: true,
		sort_by_subentity_counts: "patent_id",
		page: 1,
	}),
	s: JSON.stringify([
		{ patent_id: "desc" },
		{ assignee_total_num_patents: "desc" },
		{ assignee_organization: "asc" },
		{ assignee_last_name: "asc" },
		{ assignee_first_name: "asc" },
	]),
});
