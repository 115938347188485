const addOneTofield = (field: any) => {
	return field ? field + 1 : 1;
};

export const countPatentsByWipoSector = (data: any) => {
	let patentWIPOCategoryCountByYear: any = {};

	data?.patents?.forEach((patent: any) => {
		const patentDate = new Date(patent.patent_date).getFullYear();

		if (!patentWIPOCategoryCountByYear[patentDate]) {
			patentWIPOCategoryCountByYear[patentDate] = {};
		}
		patent?.wipos?.forEach((wipoData: any) => {
			if (wipoData.wipo_sector_title) {
				patentWIPOCategoryCountByYear[patentDate] = {
					...patentWIPOCategoryCountByYear[patentDate],
					[wipoData.wipo_sector_title]: addOneTofield(
						patentWIPOCategoryCountByYear[patentDate][wipoData.wipo_sector_title]
					),
				};
			} else {
				patentWIPOCategoryCountByYear[patentDate] = {
					...patentWIPOCategoryCountByYear[patentDate],
					Other: addOneTofield(patentWIPOCategoryCountByYear[patentDate]["Other"]),
				};
			}
		});
	});

	return patentWIPOCategoryCountByYear;
};
