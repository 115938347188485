import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ApiContextProvider } from "./HttpService";
import { AppApi } from "./api";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { AppContextProvider } from "./context/AppContext";

ReactDOM.render(
	<React.StrictMode>
		<ApiContextProvider api={AppApi}>
			<AppContextProvider>
				<App />
			</AppContextProvider>
		</ApiContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
