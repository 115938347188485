import { API_URL } from "../config";
import { Api, FetchHttpService, HttpServiceConstructor } from "../HttpService";

class AppApi extends Api {
	constructor() {
		super(API_URL, { headers: { "Content-Type": "application/json" } });
	}

	protected config(): void {
		this.registerEndpoint("/patents/query");
		this.registerEndpoint("/assignees/query");
	}

	protected getHttpService(): HttpServiceConstructor {
		return FetchHttpService;
	}
}

const instance = new AppApi();
export { instance as AppApi };
