import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

interface PatentsFilledGraphicProps {
	patentsToShow: any;
}

export const PatentsFilledGraphic = ({ patentsToShow }: PatentsFilledGraphicProps) => {
	const [state, setstate] = useState<any>({});

	useEffect(() => {
		setstate({
			options: {
				chart: {
					id: "year",
				},
				xaxis: {
					categories: Object.keys(patentsToShow),
				},
			},
			series: [
				{
					name: "Patents filed",
					data: Object.values(patentsToShow),
				},
			],
		});
	}, [patentsToShow]);

	return (
		<ChartContainer>
			{!!state.options && (
				<Chart type="area" options={state.options} series={state.series} height={450} width="100%" />
			)}
		</ChartContainer>
	);
};

const ChartContainer = styled.div`
	width: 100%;
	min-width: 1200px;
`;
