import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import { useAppContext } from "../../../../context/AppContext";
import { capitalizeFirstLetter, countPatentsByCategory } from "../../../../utils";

interface DonutData {
	options: any;
	series: any[];
	labels: any[];
}

export const ActivePatentsGraphic = () => {
	const { companyGrantedPatents } = useAppContext();
	const [donutData, setDonutData] = useState<DonutData>({
		options: {},
		series: [],
		labels: [],
	});

	useEffect(() => {
		if (!Object.values(companyGrantedPatents).length) return;
		const patentTypesCount = countPatentsByCategory(companyGrantedPatents);

		setDonutData({
			options: {
				labels: Object.keys(patentTypesCount).map((label) => capitalizeFirstLetter(label)),
			},
			series: Object.values(patentTypesCount),
			labels: Object.keys(patentTypesCount),
		});
	}, [companyGrantedPatents]);

	return (
		<GraphicContainer>
			<Title>Patents by type</Title>
			<GraphicWrapper>
				<Chart options={donutData.options} series={donutData.series} type="donut" width="100%" />
			</GraphicWrapper>
		</GraphicContainer>
	);
};

const GraphicContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 20px;
	overflow-y: hidden;
	overflow-x: auto;
	@media screen and (min-width: 1920px) {
		overflow-x: hidden;
	}
`;

const Title = styled.h4`
	align-self: flex-start;
`;
const GraphicWrapper = styled.div`
	width: 100%;
	max-width: 400px;
`;
