import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Analytics, Dashboard, SearchCompany } from "./pages";

const App = () => (
	<Router>
		<Routes>
			<Route path="/" element={<SearchCompany />} />
			<Route path="/analytics" element={<Analytics />} />
			<Route path="/dashboard" element={<Dashboard />} />
		</Routes>
	</Router>
);

export default App;
