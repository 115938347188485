import { ChangeEvent } from "react";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

interface SearchBoxProps {
	searchValue: string;
	handleSearch: () => Promise<void>;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBox = ({ searchValue, handleChange, handleSearch }: SearchBoxProps) => {
	return (
		<FormControl fullWidth variant="outlined" sx={{ maxWidth: "800px" }}>
			<InputLabel htmlFor="outlined-search-box">Search for a company...</InputLabel>
			<OutlinedInput
				fullWidth
				id="outlined-search-box"
				label="Search for a company..."
				type="search"
				value={searchValue}
				onChange={handleChange}
				endAdornment={
					<InputAdornment position="end">
						<IconButton aria-label="loop search icon" onClick={handleSearch} edge="end">
							<SearchOutlined />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};
