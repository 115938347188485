import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import { capitalizeFirstLetter } from "../../utils";
import { Header, ListResults, PatentsFilledGraphic } from "../../components";
import { useFindAssignees } from "../../api/endpoints/Assignees/useFindAssignees";
import { buildQueryToGetCompanies } from "../../utils/buildQueryToGetCompanies";
import { CompaniesTable } from "../../components/CompaniesTable";
import { tabsEnum, tabsArray } from "./utils";

interface TabProps {
	active: boolean;
}

export const DashboardContainer = () => {
	const { assigneesData, setCompanyData, setAssigneesData, allPatentsByYear } = useAppContext();
	const [patentsToShow, setpatentsToShow] = useState(allPatentsByYear);
	const [activeTab, setActiveTab] = useState(tabsEnum.OVERVIEW);
	const [companiesToShow, setCompaniesToShow] = useState([]);
	const { call: callFindAssignee } = useFindAssignees();
	const navigate = useNavigate();

	useEffect(() => {
		setpatentsToShow(allPatentsByYear);
	}, [allPatentsByYear]);

	const handleClick = (company: any) => {
		setCompanyData(company);
		setAssigneesData({});
		navigate("/analytics");
	};

	useEffect(() => {
		(async () => {
			const query = buildQueryToGetCompanies();
			const data = await callFindAssignee({ query });
			setCompaniesToShow(data.assignees);
		})();
	}, [callFindAssignee]);

	const handleTabClick = (tab: string) => () => {
		setActiveTab(tab);
	};

	return (
		<Grid container minHeight="100vh" justifyContent="flex-start" alignItems="center" flexDirection="column">
			<Header />
			<Grid item xl={5} md={7} xs={12} marginRight={2}>
				{!!assigneesData?.assignees?.length && (
					<ListResults searchResults={assigneesData.assignees} handleClick={handleClick} />
				)}
			</Grid>
			<Grid item container justifyContent="center" xl={9} md={10} xs={12} marginTop={8} paddingX="2%">
				<Card>
					<Typography variant="h5" fontWeight="bold">
						Dashboard
					</Typography>
					<TabsContainer>
						{tabsArray.map((tab: string) => (
							<Tab key={tab} active={tab === activeTab} onClick={handleTabClick(tab)}>
								{capitalizeFirstLetter(tab)}
							</Tab>
						))}
					</TabsContainer>
					<Typography variant="h6" marginTop="15px" marginLeft="15px" fontWeight="bold">
						Total Patents
					</Typography>
					<GraphicContainer>
						<PatentsFilledGraphic patentsToShow={patentsToShow} />
					</GraphicContainer>
					<Typography variant="h6" marginTop="15px" marginLeft="15px" marginBottom="10px" fontWeight="bold">
						Companies
					</Typography>
					<CompaniesTable companiesToShow={companiesToShow} />
				</Card>
			</Grid>
		</Grid>
	);
};

const Card = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.8);
	padding: 2%;
	border-radius: 10px;
	background-color: #fff;
	width: 90%;
`;

const TabsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 25px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 10px;
`;

const Tab = styled.span<TabProps>`
	padding: 3px 12px;
	border-radius: 5px;
	font-weight: 500;
	font-size: 15px;
	background-color: ${({ active }) => (active ? "#ebebeb" : "none")};
	margin-left: 5px;
	cursor: pointer;
	&:first-child {
		margin-left: 0;
	}
`;

const GraphicContainer = styled.div`
	width: 100%;
	margin-top: 30px;
	overflow-y: hidden;
	overflow-x: auto;
	@media screen and (min-width: 1920px) {
		overflow-x: hidden;
	}
`;
