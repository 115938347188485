import { ChangeEvent, useState, useEffect, useCallback } from "react";
import { useFindAssignees } from "../../api/endpoints/Assignees/useFindAssignees";
import { SearchBox } from "../../components";
import { useAppContext } from "../../context/AppContext";
import { buildQueryToSearchCompany } from "../../utils";

export const SearchContainer = () => {
	const [searchValue, setSearchValue] = useState("");
	const { call } = useFindAssignees();
	const { setAssigneesData } = useAppContext();

	const handleSearch = useCallback(async () => {
		if (!searchValue) return;
		const query = buildQueryToSearchCompany(searchValue);
		const data = await call({ query });
		setAssigneesData(data);
	}, [setAssigneesData, call, searchValue]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			handleSearch();
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [handleSearch, searchValue]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	return <SearchBox handleSearch={handleSearch} searchValue={searchValue} handleChange={handleChange} />;
};
