export const filterPatents = (data: any) => {
	let expirePatents = 0,
		activePatents = 0,
		uniqueFamilyPatent = 0;
	let alreadyAddedPatentClass: string[] = [];

	data?.patents?.forEach((patent: any) => {
		const id = patent.uspcs?.[0]?.uspc_mainclass_id;

		if (id === null) {
			activePatents++;
			uniqueFamilyPatent++;
		} else if (id === "No longer published") {
			expirePatents++;
		} else if (!alreadyAddedPatentClass.includes(id)) {
			alreadyAddedPatentClass.push(id);
			uniqueFamilyPatent++;
		}
	});

	return {
		expirePatents,
		activePatents,
		uniqueFamilyPatent,
	};
};
