export const buildQueryToGetGrantedPatents = (id: string): any => ({
  q: JSON.stringify({
    _and: [{ assignee_id: id }],
  }),
  f: JSON.stringify([
    "patent_id",
    "patent_title",
    "patent_type",
    "patent_num_cited_by_us_patents",
    "wipo_field_title",
    "wipo_sector_title",
    "wipo_sequence",
    "assignee_city",
    "assignee_country",
    "inventor_first_name",
    "inventor_last_name",
    "patent_abstract",
    "app_type",
    "app_date",
    "patent_date",
    "patent_number",
    "patent_date",
    "patent_processing_time",
  ]),
  o: JSON.stringify({
    per_page: 10000,
    matched_subentities_only: false,
    page: 1,
  }),
  s: JSON.stringify([
    { patent_num_cited_by_us_patents: "desc" },
    { patent_title: "asc" },
    { patent_date: "desc" },
  ]),
});
