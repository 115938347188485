import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import { useAppContext } from "../../../../context/AppContext";
import { countPatentsByWipoSector } from "../../../../utils";

export const PatentsByCategoryGraphic = () => {
	const { companyGrantedPatents } = useAppContext();
	const [graphicData, setGraphicData] = useState<any>({
		options: {},
		series: [],
		labels: [],
	});
	useEffect(() => {
		if (!Object.values(companyGrantedPatents).length) return;

		const result = countPatentsByWipoSector(companyGrantedPatents);

		const allFields: any = [];
		Object.values(result).forEach((data: any) => {
			const fields = Object.keys(data);
			fields.forEach((field) => {
				if (!allFields.includes(field)) {
					allFields.push(field);
				}
			});
		});

		const fieldsAndCounters: any = {};

		allFields.forEach((field: string) => {
			fieldsAndCounters[field] = [];
		});

		Object.keys(result).forEach((year: string) => {
			allFields.forEach((field: string) => {
				let newValue;
				if (!!result[year][field]) {
					newValue = result[year][field];
				} else {
					newValue = undefined;
				}
				fieldsAndCounters[field].push(newValue);
			});
		});

		const series = Object.entries(fieldsAndCounters).map(([field, data]) => {
			return {
				name: field,
				type: "column",
				data: data,
			};
		});

		var options = {
			series,
			chart: {
				stacked: false,
			},
			xaxis: {
				categories: Object.keys(result),
			},
			yaxis: [
				{
					seriesName: "Column A",
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
					},
					title: {
						text: "Patents",
					},
				},
			],
			tooltip: {
				shared: false,
				intersect: true,
				x: {
					show: false,
				},
			},
			legend: {
				horizontalAlign: "left",
			},
		};

		setGraphicData({
			options,
			series,
			labels: Object.keys(fieldsAndCounters),
		});
	}, [companyGrantedPatents]);

	return (
		<GraphicContainer>
			<Title>Patens by sectors through the years</Title>
			<GraphicWrapper>
				<Chart
					options={graphicData.options}
					width="100%"
					height={450}
					series={graphicData.series}
					type="line"
				/>
			</GraphicWrapper>
		</GraphicContainer>
	);
};

const GraphicContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 20px;
	overflow-y: hidden;
	overflow-x: auto;
	@media screen and (min-width: 1920px) {
		overflow-x: hidden;
	}
`;

const GraphicWrapper = styled.div`
	width: 100%;
	min-width: 1200px;
`;

const Title = styled.h4`
	align-self: flex-start;
`;
