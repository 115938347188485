import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

interface PatentsByYear {
	year_id: string;
	year_num_patents_for_assignee: string;
}

interface AnalyticsGraphicProps {
	companyYearlyFilledPatents: any;
}

export const AnalyticsGraphic = ({ companyYearlyFilledPatents }: AnalyticsGraphicProps) => {
	const [state, setstate] = useState<any>({});

	useEffect(() => {
		setstate({
			options: {
				chart: {
					id: "year",
				},
				xaxis: {
					categories: companyYearlyFilledPatents?.years
						?.map(({ year_id }: PatentsByYear) => Number(year_id))
						.sort(),
				},
			},
			series: [
				{
					name: "Patents filled",
					data: companyYearlyFilledPatents?.years
						?.map((obj: PatentsByYear) => Number(obj.year_num_patents_for_assignee))
						.sort(),
				},
			],
		});
	}, [companyYearlyFilledPatents]);

	return (
		<GraphicWrapper>
			{!!state.options && (
				<Chart type="line" options={state.options} series={state.series} height={450} width="100%" />
			)}
		</GraphicWrapper>
	);
};

const GraphicWrapper = styled.div`
	width: 100%;
	min-width: 1200px;
`;
