export const buildQueryToGetCompanies = (): any => ({
	q: JSON.stringify({
		_and: [
			{
				_or: [{ _and: [{ _contains: { inventor_country: "US" } }] }],
			},
		],
	}),
	f: JSON.stringify(["assignee_id", "assignee_organization", "assignee_total_num_patents"]),
	o: JSON.stringify({
		per_page: 60,
		page: 1,
	}),
	s: JSON.stringify([{ assignee_total_num_patents: "desc" }]),
});
