export const buildQueryToGetAllPatents = (year: number): any => ({
	q: JSON.stringify({
		_and: [
			{
				_or: [
					{
						_and: [
							{
								_gte: {
									app_date: `${year}-01-01`,
								},
							},
							{
								_lte: {
									app_date: `${year}-12-31`,
								},
							},
						],
					},
				],
			},
		],
	}),
	f: JSON.stringify(["patent_id", "app_date", "patent_number"]),
});
