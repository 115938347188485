import { HttpService, HttpServiceConfig } from "./HttpService";
import { EndpointManager } from "./EndpointManager";

export interface SessionConfiguration {
	name: string;
	header: string;
}

export type HttpServiceConstructor = new (initialConfig?: Partial<HttpServiceConfig>) => HttpService;

export abstract class Api {
	protected baseUrl: string;
	protected options: Omit<HttpServiceConfig, "baseUrl">;
	protected _httpService?: HttpService;
	protected endpointManager = new EndpointManager();

	constructor(baseUrl: string, options?: Omit<HttpServiceConfig, "baseUrl">) {
		this.baseUrl = baseUrl;
		this.options = options || {};
		this.init();
	}

	get httpService() {
		if (!this._httpService) {
			throw new Error("Http Service not configured properly");
		}
		return this._httpService;
	}

	private init() {
		const HttpConfiguredService = this.getHttpService();
		this._httpService = new HttpConfiguredService({ baseUrl: this.baseUrl, ...this.options });
		this.config();
	}

	protected registerEndpoint(path: string) {
		this.endpointManager.register(path);
		return this;
	}

	getEndpoint(path: string) {
		const [RESTEndpointConfigured] = this.endpointManager.get(path);
		const instance = new RESTEndpointConfigured(path);
		instance.httpService = this.httpService;
		return instance;
	}

	protected abstract getHttpService(): HttpServiceConstructor;
	protected abstract config(): void;
}
