import styled from "styled-components";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Business } from "@mui/icons-material";

interface ListResultProps {
	searchResults: any[];
	handleClick: (company: any) => void;
}

export const ListResults = ({ searchResults, handleClick }: ListResultProps) => {
	return (
		<ListContainer>
			<List
				sx={{
					width: "100%",
					maxWidth: "inherit",
					bgcolor: "background.paper",
					position: "relative",
					overflow: "auto",
					maxHeight: 300,
				}}>
				{searchResults.map((company) => (
					<ListItem key={company.assignee_id} onClick={() => handleClick(company)} disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<Business />
							</ListItemIcon>
							<ListItemText
								primary={company.assignee_organization}
								secondary={`Number of patents: ${company.assignee_total_num_patents}`}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</ListContainer>
	);
};

const ListContainer = styled.div`
	padding: 2px;
	border-radius: 5px;
	background-color: #1976d2;
	max-height: 300;
	margin-top: 10px;
	width: 100%;
`;
