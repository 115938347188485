import { useState } from "react";
import {
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

export const CompaniesTable = ({ companiesToShow }: any) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const theme = useTheme();
	const count = companiesToShow?.length;
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFirstPageButtonClick = () => {
		setPage(0);
	};

	const handleBackButtonClick = () => {
		setPage(page - 1);
	};

	const handleNextButtonClick = () => {
		setPage(page + 1);
	};

	const handleLastPageButtonClick = () => {
		setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Assignee ID</TableCell>
						<TableCell>Company</TableCell>
						<TableCell>Patents filled</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
					{(rowsPerPage > 0
						? companiesToShow?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: companiesToShow
					)?.map((company: any) => (
						<TableRow key={company.assignee_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell component="th" scope="row">
								{company.assignee_id}
							</TableCell>
							<TableCell>{company.assignee_organization}</TableCell>
							<TableCell>{company.assignee_total_num_patents}</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={companiesToShow?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={() => {
								return (
									<Box sx={{ flexShrink: 0, ml: 2.5 }}>
										<IconButton
											onClick={handleFirstPageButtonClick}
											disabled={page === 0}
											aria-label="first page">
											{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
										</IconButton>
										<IconButton
											onClick={handleBackButtonClick}
											disabled={page === 0}
											aria-label="previous page">
											{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
										</IconButton>
										<IconButton
											onClick={handleNextButtonClick}
											disabled={page >= Math.ceil(count / rowsPerPage) - 1}
											aria-label="next page">
											{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
										</IconButton>
										<IconButton
											onClick={handleLastPageButtonClick}
											disabled={page >= Math.ceil(count / rowsPerPage) - 1}
											aria-label="last page">
											{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
										</IconButton>
									</Box>
								);
							}}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};
