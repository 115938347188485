export const countPatentsByCategory = (data: any) => {
	let patentTypesCount: any = {};

	data?.patents?.forEach((patent: any) => {
		if (patentTypesCount[patent.patent_type]) {
			patentTypesCount[patent.patent_type] += 1;
		} else {
			patentTypesCount[patent.patent_type] = 1;
		}
	});

	return patentTypesCount;
};
