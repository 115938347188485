export const buildQueryToGetFilledPatents = (company: string): any => ({
  q: JSON.stringify({
    _and: [{ assignee_id: company }],
  }),
  f: JSON.stringify([
    "app_date",
    "patent_id",
    "patent_title",
    "patent_date",
    "patent_processing_time",
    "patent_type",
    "patent_number",
    "patent_num_cited_by_us_patents",
    "examiner_group",
    "assignee_state",
    "inventor_id",
  ]),
  o: JSON.stringify({
    per_page: 10000,
    matched_subentities_only: false,
    page: 1,
  }),
});
