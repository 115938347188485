export const buildQueryToSearchPatents = (id: string): any => ({
  q: JSON.stringify({
    _and: [{ assignee_id: id }],
  }),
  f: JSON.stringify([
    "patent_id",
    "patent_title",
    "uspc_sequence",
    "uspc_mainclass_id",
    "uspc_mainclass_title",
    "patent_number",
    "inventor_id",
  ]),
  o: JSON.stringify({
    per_page: 10000,
    matched_subentities_only: false,
    page: 1,
  }),
  s: JSON.stringify([
    { patent_num_cited_by_us_patents: "desc" },
    { patent_title: "asc" },
    { patent_date: "desc" },
  ]),
});
