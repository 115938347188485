import { RESTEndpoint } from "./RESTEndpoint";

type RESTEndpointConstructor = new (path: string) => RESTEndpoint;
type AnyObject = { [key: string]: any };

export class EndpointManager {
	private endpoints = new Map<string, RESTEndpointConstructor>();
	private options = new Map<string, AnyObject>();

	register(path: string, RESTEndpointClass: RESTEndpointConstructor = RESTEndpoint, options: AnyObject = {}) {
		this.endpoints.set(path, RESTEndpointClass);
		this.options.set(path, options);
	}

	get(path: string): [RESTEndpointConstructor, AnyObject] {
		if (!this.endpoints.has(path)) {
			throw new Error(`Unregistered endpoint ${path}`);
		}
		return [this.endpoints.get(path) as RESTEndpointConstructor, this.options.get(path) as AnyObject];
	}
}
