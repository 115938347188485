import { useApiEndpoint } from "./useApiEndpoint";
import { useCallback, useRef, useState } from "react";
import { QueryObject } from "../../RESTEndpoint";

export interface ApiFindOptions {
	query?: QueryObject;
}

export interface ApiFindReturn<T> {
	data: T | null | undefined;
	error?: any;
	loading: boolean;
	call: (opts?: ApiFindOptions) => Promise<T | null | undefined>;
}

export const useApiFind = <T = any>(path: string, options: ApiFindOptions = {}): ApiFindReturn<T> => {
	const ServiceEndpoint = useApiEndpoint<T>(path);
	// Refs
	const queryOption = useRef<QueryObject | undefined>(options.query);

	// Inner state
	const [data, setData] = useState<T | null | undefined>(undefined);
	const [error, setError] = useState<any>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	const call = useCallback(
		async (opts: ApiFindOptions = {}) => {
			const { query } = opts;
			try {
				setLoading(true);
				const queryToUse = query || queryOption.current;
				const response = await ServiceEndpoint.find(queryToUse);
				setError(undefined);
				setData(response.data);
				return response.data;
			} catch (e: any) {
				setData(undefined);
				setError(e.error);
				throw e;
			} finally {
				setLoading(false);
			}
		},
		[ServiceEndpoint]
	);

	return { data, loading, error, call };
};
