import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import { AnalyticsGraphic } from "../AnalyticsGraphic";

export const BasicCompanyInformation = () => {
	const { companyYearlyFilledPatents, companyData, companyNumberOfPatents } = useAppContext();

	return (
		<>
			<Grid container justifyContent="space-between" marginY={5} paddingX={2}>
				<Grid item>
					<Typography>Total patents</Typography>
					<Typography fontWeight="500">{companyData.assignee_total_num_patents}</Typography>
				</Grid>
				<Grid item>
					<Typography>Unique patent Families</Typography>
					<Typography fontWeight="500" textAlign="right">
						{companyNumberOfPatents.uniqueFamilyPatent}
					</Typography>
				</Grid>
			</Grid>
			<Grid container justifyContent="space-between" marginY={5} paddingX={2}>
				<Grid item>
					<Typography>Active Patents</Typography>
					<Typography fontWeight="500">{companyNumberOfPatents.activePatents}</Typography>
				</Grid>
				<Grid item>
					<Typography>Expired Patents</Typography>
					<Typography fontWeight="500" textAlign="right">
						{companyNumberOfPatents.expirePatents}
					</Typography>
				</Grid>
			</Grid>
			<GraphicContainer>
				<AnalyticsGraphic companyYearlyFilledPatents={companyYearlyFilledPatents} />
			</GraphicContainer>
		</>
	);
};

const GraphicContainer = styled.div`
	width: 100%;
	margin-top: 30px;
	overflow-x: auto;
	overflow-y: hidden;
	@media screen and (min-width: 1920px) {
		overflow-x: hidden;
	}
`;
