import { HttpService } from "./HttpService";

export type QueryObject = {
	[k: string]: string | number;
};

export const getQueryString = (query: QueryObject) =>
	Object.entries(query)
		.map(([key, value]) => `${key}=${value}`)
		.join("&");

export class RESTEndpoint<T = any> {
	protected path: string;
	protected _httpService?: HttpService;

	constructor(path: string) {
		this.path = path;
	}

	get httpService() {
		if (!this._httpService) {
			throw new Error("Http Service not set");
		}
		return this._httpService;
	}

	set httpService(_httpService: HttpService) {
		this._httpService = _httpService;
	}

	getFullPathWithQuery(query: QueryObject = {}, suffix?: string) {
		const url = suffix ? `${this.path}/${suffix}` : this.path;
		if (!Object.keys(query).length) {
			return url;
		}
		return `${url}?${getQueryString(query)}`;
	}

	find(query?: QueryObject) {
		return this.httpService.get<T>(this.getFullPathWithQuery(query));
	}

	create(body: T, assetType?: string) {
		const url = assetType ? `${this.path}/${assetType}` : this.path;
		return this.httpService.post<T>(url, body);
	}

	update(id?: string, body?: T, assetType?: string) {
		let url = this.path;
		if (id) {
			url = `${this.path}/${id}`;
		} else if (assetType) {
			url = `${this.path}/${assetType}`;
		}
		return this.httpService.put<T>(url, body || {});
	}

	remove<S = T>(id?: string, body?: T, assetType?: string) {
		let url = this.path;
		if (id) {
			url = `${this.path}/${id}`;
		} else if (assetType) {
			url = `${this.path}/${assetType}`;
		}
		return this.httpService.delete<S>(url, body || {});
	}
}
