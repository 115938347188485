import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import { ListResults } from "../../components/ListResults";
import { useAppContext } from "../../context/AppContext";
import { SearchContainer } from "../../containers/SearchContainer";
import { Dashboard } from "@mui/icons-material";

export const SearchCompany = () => {
	const { setCompanyData, assigneesData, setAssigneesData } = useAppContext();
	const navigate = useNavigate();

	const handleClick = (company: any) => {
		setCompanyData(company);
		setAssigneesData({});
		navigate("/analytics");
	};

	return (
		<Grid container height="100vh" justifyContent="center" alignItems="center">
			<Grid item xl={5} md={7}>
				<MainContainer>
					<Grid container flexDirection="column">
						<Typography variant="h5" fontWeight="bold">
							<IconWrapper>
								<Dashboard />
							</IconWrapper>
							Patent analytics beta
						</Typography>
						<Typography>Track a company's patents from application to expiration</Typography>
					</Grid>
					<Grid marginTop={6}>
						<SearchContainer />
					</Grid>
					{!!assigneesData?.assignees?.length && (
						<ListResults searchResults={assigneesData.assignees} handleClick={handleClick} />
					)}
				</MainContainer>
			</Grid>
		</Grid>
	);
};

const MainContainer = styled.div`
	width: 100%;

	@media screen and (max-width: 600px) {
		width: 85%;
		margin: 0 auto;
	}
`;

const IconWrapper = styled.span`
	position: relative;
	top: 4px;
`;
